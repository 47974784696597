/* Default styles for all screen sizes */
.contact {
  background-color: var(--primary-color);
  padding: 2rem;
}

.contact h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.social-media a {
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  border: none;
  padding: 0.5rem;
  border-radius: 25%;
  width: 3rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media a img {
  width: 100%;
  height: 100%;
}

/* Media query for screens less than 600 pixels wide */
@media (max-width: 600px) {
  .contact {
    padding: 1rem;
  }

  .social-media {
    flex-wrap: wrap;
  }

  .social-media a {
    margin: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}