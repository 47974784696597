:root {
  --primary-color: #F4EBD0;
  --secondary-color: #122647;
  --accent-color: #D6AD60;
  --extra: #B68D40;

  --primary-color-dark: #797569;
  --secondary-color-dark: #131c19;
  --accent-color-dark: #6d5a38;
  --extra-dark: #5f4b2a;
}

.App {
  text-align: center;
  background-color: var(--primary-color); /* updated color */
}

.App-header {
  background-color: var(--accent-color);
  min-height: 200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--accent-color); /* updated color */
}

.App-link {
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  background-color: var(--secondary-color);
  color: #fff;
  padding: 1rem;
  height: 2rem;
  font-size: 20px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin: 0 1rem;
}

nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

nav a:hover {
  color: #f7f7f7;
}

main {
  padding-top: 4rem;
  padding-left: 6rem;
  padding-right: 6rem;
}

main h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

main p {
  font-size: 1.2rem;
  line-height: 1.5;
}

/* Media query for screens less than 600 pixels wide */
@media (max-width: 600px) {
  header {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    padding: 0.5rem;
  }

  nav li {
    margin: 0 0.5rem;
  }

  main {
    padding: 1rem;
  }

  main h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  main p {
    font-size: 1rem;
    line-height: 1.2;
  }
}
