/* Default styles for all screen sizes */
.about {
  background-color: var(--primary-color);
  padding: 2rem;
  padding-top: 4rem;
  margin-bottom: 2rem;
}

.about h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about p {
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 1rem; /* Add padding to the text */
}

.about img {
  display: block;
  margin: 0 auto;
  max-width: 20rem;
  height: auto;
  width:  100%;
  border-radius: 50%;
  margin-bottom: 1rem;
}

/* Media query for screens less than 600 pixels wide */
@media (max-width: 600px) {
  .about {
    background-color: var(--primary-color); /* updated color */
    padding: 2rem;
    padding-top: 4rem;
    margin-bottom: 2rem;
  }
  
  .about h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .about p {
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 1rem;
  }
}