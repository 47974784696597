.portfolio {
  background-color: var(--primary-color);
  padding: 2rem;
  margin-bottom: 2rem;
}

.portfolio h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.portfolio img {
  display: block;
  margin: 0 auto;
  max-width: 20rem;
  height: auto;
  width:  100%;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 2rem;
  grid-row-gap: 4rem;
  place-items: center;
}

.project {
  display: flex;
  flex-direction: column;
  background-color: var(--accent-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.25rem;
  height: 95%;
  width: 90%;
  max-width: 500px;
  transition: transform 0.3s ease-in-out;
}

.project img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 0.25rem;
  margin-top: auto; /* optional, for rounded corners */
}

.project:hover {
  transform: scale(1.05); /* adjust as needed */
}

.portfolio h2 {
  margin-bottom: 50px; /* adjust as needed */
}

@media (max-width: 600px) {
  .project-grid {
    grid-template-columns: repeat(1, 1fr); /* Change to a single column */
  }
}